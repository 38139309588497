import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../layout"
import SEO from "../seo"

import { Cover } from "../anti/cover/cover"

const Login = () => {
  const data = useStaticQuery(graphql`
    query {
      wordPress {
        page(id: "subscribe", idType: URI) {
          title
          slug
          blocks {
            ... on WordPress_AcfCoverMainBlock {
              coverMain {
                img {
                  mediaItemUrl
                }
                label
                title
              }
            }
          }
        }
      }
    }
  `)

  const wp = data.wordPress.page

  useEffect(() => {
    setTimeout(() => {
      if (window.gigya) {
        window.gigya.accounts.showScreenSet({
          screenSet: "SML-LiteRegistration",
          startScreen: "gigya-subscribe-with-email-screen",
          containerID: "screen",
        })
      }
    }, 1000)
  }, [])

  return (
    <Layout altNav>
      <SEO title={wp.title} />

      <Cover
        variant="basic"
        theme="dark"
        img={wp?.blocks[0]?.coverMain?.img?.mediaItemUrl}
        //breadcrumb={<Breadcrumb path={location.pathname} />}
        imgHeight="h-500px h-md-600px"
        imgOverlay="30"
        label={wp?.blocks[0]?.coverMain?.label}
        title={wp?.blocks[0]?.coverMain?.title}
        className="cover-full cover-main"
      />

      <div id="screen"></div>
    </Layout>
  )
}

export default Login